import { observer } from "mobx-react-lite";
// material
import { styled } from "@mui/material/styles";
import { Card, Typography, Grid, Stack } from "@mui/material";
import GameStatusPanel from "./gameStatusPanel";
import { useCurrentStudentStore } from "../../stores/useStore";
import { fPercent } from "../../utils/formatNumber";
import GameNimStore from "../../stores/gameNimStore";

//#region Styles

const GameTypeWrapperStyle = styled(Card)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  position: "relative",
  textAlign: "center",
  color: "white",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "rgba(255, 255, 255, 0.3)",
}));

const GameTypeTitleStyle = styled(Typography)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.2)",
  fontWeight: "bold",
  padding: theme.spacing(1),
}));

const GameAIWrapperStyle = styled(Card)(({ theme }) => ({
  textAlign: "center",
  color: "white",
  backgroundColor: "transparent",
}));

//#endregion

//#region Component

const GameAICard = ({
  info,
}: {
  info: {
    title: string;
    playCount: number;
    winRate?: number;
  };
}) => {
  return (
    <Grid item md={4} xs={12}>
      <GameAIWrapperStyle>
        <Stack direction="column" alignItems="center" spacing={1}>
          <Typography variant="h6">{info.title}</Typography>
          <Typography variant="body1">{`次数 ${info.playCount}｜胜率 ${
            info.winRate ? fPercent(info.winRate * 100) : "--"
          }`}</Typography>
        </Stack>
      </GameAIWrapperStyle>
    </Grid>
  );
};

const GameTypeCard = ({
  title,
  info,
}: {
  title: string;
  info: {
    title: string;
    playCount: number;
    winRate?: number;
  }[];
}) => {
  return (
    <Grid item md={12} xs={12}>
      <GameTypeWrapperStyle>
        <Stack direction="column" alignItems="stretch" spacing={1}>
          <GameTypeTitleStyle variant="h6">{title}</GameTypeTitleStyle>
          <Grid container rowSpacing={1} columnSpacing={0}>
            {info.map((item) => (
              <GameAICard key={item.title} info={item} />
            ))}
          </Grid>
        </Stack>
      </GameTypeWrapperStyle>
    </Grid>
  );
};

const Content = observer(
  ({
    hideEntryButton,
    gameNim,
  }: {
    hideEntryButton: boolean;
    gameNim: GameNimStore;
  }) => {
    return (
      <GameStatusPanel
        hideEntryButton={hideEntryButton}
        gameId="nim"
        title="尼姆游戏"
        lastPlayTime={gameNim.lastPlayTime}
        themeColor="#FD6E22"
        cornerWidget={null}
      >
        <Grid container rowSpacing={1.2} columnSpacing={2}>
          <GameTypeCard title="单行" info={gameNim.singleLineState} />
          <GameTypeCard title="多行" info={gameNim.multipleLineState} />
        </Grid>
      </GameStatusPanel>
    );
  }
);

const GameStatusNim = ({
  hideEntryButton = false,
}: {
  hideEntryButton?: boolean;
}) => {
  const { gameNim } = useCurrentStudentStore();
  return <Content hideEntryButton={hideEntryButton} gameNim={gameNim} />;
};

//#endregion

export default GameStatusNim;
