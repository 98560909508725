import { useState, useRef, useEffect } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
// material
import {
  Card,
  Table,
  Stack,
  IconButton,
  Button,
  Menu,
  MenuItem,
  TableRow,
  ListItemIcon,
  ListItemText,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { useSnackbar } from "notistack";
// components
import Iconify from "../components/Iconify";
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import SearchNotFound from "../components/SearchNotFound";
import StudentListHead from "../sections/@dashboard/studentsPage/StudentListHead";
//
import StudentListStore from "../stores/studentListStore";
import { useStudentListStore } from "../stores/useStore";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "username", label: "用户名", alignRight: false },
  { id: "email", label: "邮箱", alignRight: false },
  { id: "lastLoginTime", label: "最近登录时间", alignRight: false },
  { id: "isVerified", label: "验证邮箱", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

const StudentMenu = observer(
  ({ id, store }: { id: string; store: StudentListStore }) => {
    const ref = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    return (
      <>
        <IconButton ref={ref} onClick={() => setIsOpen(true)}>
          <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
        </IconButton>

        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: "100%" },
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <MenuItem
            onClick={async () => {
              try {
                console.info(id);
                await store.deleteStudent(id);
                enqueueSnackbar("删除成功", { variant: "success" });
              } catch (error) {
                if (error instanceof Error) {
                  enqueueSnackbar(error.message, { variant: "error" });
                  return;
                }
                enqueueSnackbar(`${error}`, { variant: "error" });
              }
            }}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="删除"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
          <MenuItem
            component={RouterLink}
            to={`/dashboard/student/${id}`}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <Iconify icon="eva:info-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="查看详情"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        </Menu>
      </>
    );
  }
);

const StudentsTable = observer(({ store }: { store: StudentListStore }) => {
  const navigate = useNavigate();
  const emptyRows =
    store.page > 0
      ? Math.max(0, (1 + store.page) * store.rowsPerPage - store.maxCount)
      : 0;
  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <StudentListHead
              order={store.order}
              orderBy={store.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={store.maxCount}
              numSelected={store.selectedStudents.size}
              onRequestSort={(event, property) => {
                const isAsc =
                  store.orderBy === property && store.order === "asc";
                store.updateOrder(isAsc ? "desc" : "asc", property);
              }}
              onSelectAllClick={(event) => {
                if (event.target.checked) {
                  store.selectAllAtCurrentPage();
                  return;
                }
                store.unselectAll();
              }}
            />
            <TableBody>
              {store.currentPage.students.map((row) => {
                const { id, username, email, lastLoginTime, isVerified } = row;
                const isItemSelected = store.selectedStudents.has(id);

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell component="th" scope="row" padding="normal">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography variant="subtitle2" noWrap>
                          {username}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{email}</TableCell>
                    <TableCell align="left">
                      {lastLoginTime?.toLocaleString() ?? "--"}
                    </TableCell>
                    <TableCell align="left">
                      {isVerified ? "已验证" : "未验证"}
                    </TableCell>
                    <TableCell align="right">
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={1}
                      >
                        <Button
                          variant="contained"
                          onClick={(event) => {
                            navigate(`/dashboard/student/${id}`);
                          }}
                        >
                          查看详情
                        </Button>
                        <StudentMenu id={id} store={store} />
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {store.isEmpty && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={store.maxCount}
        rowsPerPage={store.rowsPerPage}
        page={store.page}
        labelRowsPerPage="每页行数"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
        onPageChange={(event, newPage) => {
          store.loadPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          store.updatePagePerRow(parseInt(event.target.value, 10));
        }}
      />
    </Card>
  );
});

export default function User() {
  const store = useStudentListStore();

  useEffect(() => {
    store.refreshCurrentPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="我的学生 | 数学嘉年华">
      <Container>
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            我的学生
          </Typography>
        </Stack>
        <StudentsTable store={store} />
      </Container>
    </Page>
  );
}
