import http from "../utils/axios";

export async function addStudentService(args: {
  username: string;
}): Promise<void> {
  const res = await http.post<{
    error: boolean;
    message?: string;
  }>(`/api/students/add`, args);

  if (res.data.error) {
    throw new Error(res.data.message);
  }
}

export async function deleteStudentService(args: {
  id: string;
}): Promise<void> {
  const res = await http.delete<{
    error: boolean;
    message?: string;
  }>(`/api/students/detail/${args.id}`);

  if (res.data.error) {
    throw new Error(res.data.message);
  }
}