import http from "../utils/axios";

export type GameNimLevelState = {
  title: string;
  playCount: number;
  winRate?: number;
  lastPlayTime?: Date;
  averageDuration?: number;
};

export type GameNimState = {
  lastPlayTime?: Date;
  singleLineState: GameNimLevelState[];
  multipleLineState: GameNimLevelState[];
};

export default async function gameNimStateService(args: {
  studentId: string;
}): Promise<GameNimState> {
  type LevelInfo = {
    lastPlayTime?: string;
    playCount: number;
    averageDuration?: number;
    winCount: number;
  };
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      lastPlayTime?: string;
      singleRow: {
        [key: string]: LevelInfo;
      };
      multipleRow: {
        [key: string]: LevelInfo;
      };
    };
  }>(`/api/students/detail/${args.studentId}/game/nim`);

  if (res.data.error || !res.data.data) {
    throw new Error(res.data.message);
  }
  function title(id: string): string {
    switch (id) {
      case "ai1":
        return "菜鸟";
      case "ai2":
        return "拷贝猫";
      case "ai3":
        return "尼姆大师";
      default:
        return id;
    }
  }

  const data = res.data.data;
  return {
    lastPlayTime: data.lastPlayTime ? new Date(data.lastPlayTime) : undefined,
    singleLineState: Object.entries(data.singleRow).map(([id, info]) => ({
      title: title(id),
      playCount: info.playCount,
      winRate: info.playCount > 0 ? info.winCount / info.playCount : undefined,
      lastPlayTime: info.lastPlayTime ? new Date(info.lastPlayTime) : undefined,
      averageDuration: info.averageDuration ?? undefined,
    })),
    multipleLineState: Object.entries(data.multipleRow).map(([id, info]) => ({
      title: title(id),
      playCount: info.playCount,
      winRate: info.playCount > 0 ? info.winCount / info.playCount : undefined,
      lastPlayTime: info.lastPlayTime ? new Date(info.lastPlayTime) : undefined,
      averageDuration: info.averageDuration ?? undefined,
    })),
  };
}

// export default async function gameNimStateService(args: {
//   studentId: string;
// }): Promise<GameNimState> {
//   return {
//     lastPlayTime: new Date(),
//     singleLineState: [
//       {
//         title: "菜鸟",
//         playCount: 5,
//         winRate: 0.8,
//         averageDuration: 30,
//         lastPlayTime: new Date(),
//       },
//       {
//         title: "拷贝猫",
//         playCount: 15,
//         winRate: 0.3,
//         averageDuration: 30,
//         lastPlayTime: new Date(),
//       },
//       {
//         title: "尼姆大师",
//         playCount: 20,
//         winRate: 0.1,
//         averageDuration: 30,
//         lastPlayTime: new Date(),
//       },
//     ],
//     multipleLineState: [
//       {
//         title: "菜鸟",
//         playCount: 0,
//         winRate: undefined,
//         averageDuration: undefined,
//         lastPlayTime: undefined,
//       },
//       {
//         title: "拷贝猫",
//         playCount: 15,
//         winRate: 0.3,
//         averageDuration: 30,
//         lastPlayTime: new Date(),
//       },
//       {
//         title: "尼姆大师",
//         playCount: 20,
//         winRate: 0.1,
//         averageDuration: 30,
//         lastPlayTime: new Date(),
//       },
//     ],
//   };
// }
