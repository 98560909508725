import { sum } from "lodash";
import { computed, makeObservable, observable, runInAction } from "mobx";
import gameGeometryStateService, {
  GeometryLevelLevelState,
} from "../services/gameGeometryStateService";

export type LevelInfo = {
  title: string;
  type: LevelType;
} & Omit<GeometryLevelLevelState, "chapterIdentifier" | "levelIdentifier">;

export enum LevelType {
  Level,
  Tutorial,
  Knowledge,
}

function defaultLevelInfo(title: string, type: LevelType): LevelInfo {
  return {
    title,
    type,
    isFinished: false,
    startCount: 0,
    finishCount: 0,
  };
}

const defaultChaptersInfo = {
  thalesTheorem: {
    title: "泰勒斯定理",
    levels: {
      markTriangle: defaultLevelInfo("标记三角形", LevelType.Tutorial),
      provePonsAsinorum: defaultLevelInfo("拆分三角形", LevelType.Level),
      describePonsAsinorum: defaultLevelInfo("驴桥定理", LevelType.Knowledge),
      setCircleCenter: defaultLevelInfo("设置圆心", LevelType.Tutorial),
      proveThalesTheorem: defaultLevelInfo("直角作圆", LevelType.Level),
      describeThalesTheorem: defaultLevelInfo(
        "泰勒斯定理",
        LevelType.Knowledge
      ),
      "level-1": defaultLevelInfo("找出圆心", LevelType.Level),
      "level-2": defaultLevelInfo("找出垂线", LevelType.Level),
      tangentLineOfCircle: defaultLevelInfo("巧画切线", LevelType.Level),
      thalesTheorem: defaultLevelInfo("泰勒斯定理", LevelType.Tutorial),
      provePythagoreanTheorem: defaultLevelInfo(
        "泰勒斯定理中的勾股定理",
        LevelType.Level
      ),
    },
  },
  sangaku: {
    title: "算额",
    levels: {
      twoTangentCircles: defaultLevelInfo("算额一", LevelType.Level),
      fillTriangleWithInnerCircle: defaultLevelInfo("算额二", LevelType.Level),
      paintSidesOfRightTriangle: defaultLevelInfo("算额三", LevelType.Level),
      paintTwoSidesOfTriangle: defaultLevelInfo("算额四", LevelType.Level),
      thirdTangentCircle: defaultLevelInfo("第三相切圆", LevelType.Level),
      circleInversionIntroduction: defaultLevelInfo(
        "圆的反演",
        LevelType.Tutorial
      ),
      fourTangentCircles: defaultLevelInfo("四圆相切", LevelType.Level),
    },
  },
  euler: {
    title: "欧拉",
    levels: {
      "euler-circle": defaultLevelInfo("欧拉圆", LevelType.Level),
      "scale-euler-circle": defaultLevelInfo("外接圆", LevelType.Level),
      "inverse-thales-theorem": defaultLevelInfo(
        "逆泰勒斯定理",
        LevelType.Tutorial
      ),
      "find-centroid-of-right-triangle": defaultLevelInfo(
        "寻找重心",
        LevelType.Level
      ),
      "introduce-euler-line": defaultLevelInfo("欧拉线", LevelType.Knowledge),
      "search-outer-circle-tutorial": defaultLevelInfo(
        "搜索外接圆",
        LevelType.Tutorial
      ),
      "euler-line-cuts-off-equilateral-triangle": defaultLevelInfo(
        "寻找欧拉线",
        LevelType.Level
      ),
    },
  },
};

export type ChapterInfo = {
  title: string;
  levels: {
    [x: string]: LevelInfo;
  };
};

type Chapters = typeof defaultChaptersInfo & {
  thalesTheorem: ChapterInfo;
  sangaku: ChapterInfo;
  euler: ChapterInfo;
  [x: string]: ChapterInfo;
};

export default class GameGeometryStore {
  studentId: string;
  lastPlayTime?: Date;
  chapters: Chapters = defaultChaptersInfo;

  get chapterLevelCount() {
    function findCount(dict: { [x: string]: LevelInfo | undefined }): number {
      const values = Object.values(dict).filter(
        (t) => t?.type === LevelType.Level
      );
      return values.length;
    }
    return {
      thalesTheorem: findCount(this.chapters.thalesTheorem.levels),
      sangaku: findCount(this.chapters.sangaku.levels),
      euler: findCount(this.chapters.euler.levels),
    };
  }

  get chapterFinishCount() {
    function findFinishCount(dict: {
      [x: string]: LevelInfo | undefined;
    }): number {
      const values = Object.values(dict).filter(
        (t) => t?.type === LevelType.Level && t.isFinished
      );
      return values.length;
    }

    return {
      thalesTheorem: findFinishCount(this.chapters.thalesTheorem.levels),
      sangaku: findFinishCount(this.chapters.sangaku.levels),
      euler: findFinishCount(this.chapters.euler.levels),
    };
  }

  get chapterProgress() {
    const levelCount = this.chapterLevelCount;
    const finishCount = this.chapterFinishCount;

    return {
      thalesTheorem: finishCount.thalesTheorem / levelCount.thalesTheorem,
      sangaku: finishCount.sangaku / levelCount.sangaku,
      euler: finishCount.euler / levelCount.euler,
    };
  }

  get chapterProgressText() {
    const levelCount = this.chapterLevelCount;
    const finishCount = this.chapterFinishCount;

    return {
      thalesTheorem: `${finishCount.thalesTheorem} / ${levelCount.thalesTheorem}`,
      sangaku: `${finishCount.sangaku} / ${levelCount.sangaku}`,
      euler: `${finishCount.euler} / ${levelCount.euler}`,
    };
  }

  get progressText() {
    const total = sum(Object.values(this.chapterLevelCount));
    const finished = sum(Object.values(this.chapterFinishCount));

    return `${finished} / ${total}`;
  }

  constructor(studentId: string) {
    this.studentId = studentId;
    makeObservable(this, {
      chapters: observable,
      lastPlayTime: observable,
      chapterLevelCount: computed,
      chapterFinishCount: computed,
      chapterProgress: computed,
      chapterProgressText: computed,
      progressText: computed,
    });
  }

  async update() {
    const { lastPlayTime, levels } = await gameGeometryStateService({
      studentId: this.studentId,
    });

    runInAction(() => {
      const chapters = this.chapters;
      for (const level of levels) {
        const chapter = chapters[level.chapterIdentifier] ?? {
          title: level.chapterIdentifier,
          levels: {},
        };
        const l =
          chapter.levels[level.levelIdentifier] ??
          defaultLevelInfo(level.levelIdentifier, LevelType.Level);
        Object.assign(l, level);

        chapter.levels[level.levelIdentifier] = l;
        chapters[level.chapterIdentifier] = chapter;
      }

      this.lastPlayTime = lastPlayTime;
      this.chapters = chapters;
    });
  }
}
