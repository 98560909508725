import { observer } from "mobx-react-lite";
import {
  Card,
  Table,
  TableHead,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  styled,
  Popover,
  Button,
  Box,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useSnackbar } from "notistack";
import Scrollbar from "../../components/Scrollbar";
import { useCurrentStudentStore } from "../../stores/useStore";
import GameStatusGeometry from "../@studentDetail/gameStatusGeometry";
import GameGeometryStore, {
  LevelInfo,
  ChapterInfo,
  LevelType,
} from "../../stores/gameGeometryStore";
import { fDuration, fNumber } from "../../utils/formatNumber";
import { toJS } from "mobx";

const TABLE_HEAD = [
  { id: "title", label: "名称", alignRight: false },
  { id: "type", label: "类型", alignRight: false },
  { id: "state", label: "状态", alignRight: false },
  { id: "lastPlayTime", label: "最近游玩时间", alignRight: false },
  { id: "duration", label: "初次通关时长(平均)", alignRight: false },
  { id: "finishCount", label: "通关/开始次数", alignRight: false },
  { id: "step", label: "最短步数(平均)", alignRight: false },
  { id: "shortestSolution", label: "最短解答", alignRight: false },
];

const Head = () => {
  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const Title = styled("h3")({
  padding: "12px",
});

const Solution = ({ steps }: { steps: string[] }) => {
  const [showSolution, setShowSolution] = useState(false);
  const anchorElement = useRef(null as HTMLElement | null);
  return (
    <>
      <Button
        variant="contained"
        onClick={(event) => {
          setShowSolution(!showSolution);
          anchorElement.current = event.currentTarget;
        }}
      >
        查看
      </Button>
      <Popover
        open={showSolution}
        anchorEl={anchorElement.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setShowSolution(false);
        }}
      >
        <Card sx={{ minWidth: 200, minHeight: 20, padding: "1em" }}>
          <Stack direction="column">
            {steps.map((t, index) => {
              return (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: 16,
                      height: 16,
                      borderRadius: 8,
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                      fontSize: 10,
                    })}
                  >
                    {index + 1}
                  </Box>
                  <span>{t}</span>
                </Stack>
              );
            })}
          </Stack>
        </Card>
      </Popover>
    </>
  );
};

const LevelRow = ({ levelInfo }: { levelInfo: LevelInfo }) => {
  return (
    <TableRow hover key={levelInfo.title} tabIndex={-1}>
      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {levelInfo.title}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">
        {(() => {
          switch (levelInfo.type) {
            case LevelType.Level:
              return "关卡";
            case LevelType.Tutorial:
              return "教程";
            case LevelType.Knowledge:
              return "知识点";
          }
        })()}
      </TableCell>
      <TableCell align="left">
        {levelInfo.type === LevelType.Level
          ? levelInfo.isFinished
            ? "已完成"
            : "未完成"
          : "--"}
      </TableCell>
      <TableCell align="left">
        {levelInfo.lastPlayTime?.toLocaleString() ?? "--"}
      </TableCell>
      <TableCell align="left">
        {(() => {
          if (levelInfo.type !== LevelType.Level) {
            return "--";
          }
          const e = (d: number | undefined) =>
            d ? `${fDuration(d / 1000)}` : "--秒";
          return `${e(levelInfo.firstTimeFinishDuration)}(${e(
            levelInfo.averageFinishDuration
          )})`;
        })()}
      </TableCell>
      <TableCell align="left">
        {levelInfo.type === LevelType.Level
          ? `${levelInfo.finishCount}/${levelInfo.startCount}次`
          : `查看${levelInfo.startCount}次`}
      </TableCell>
      <TableCell align="left">
        {(() => {
          if (levelInfo.type !== LevelType.Level) {
            return "--";
          }
          const e = (d: number | undefined) => (d ? `${fNumber(d)}步` : "--步");
          return `${e(levelInfo.lowestStepCount)}(${e(
            levelInfo.averageStepCount
          )})`;
        })()}
      </TableCell>
      <TableCell align="left">
        {levelInfo.shortestSolution == undefined ? (
          "无"
        ) : (
          <Solution steps={levelInfo.shortestSolution} />
        )}
      </TableCell>
    </TableRow>
  );
};

const LevelTable = ({ chapterInfo }: { chapterInfo: ChapterInfo }) => {
  return (
    <Card>
      <Title>{chapterInfo.title}</Title>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <Head />
            <TableBody>
              {Object.keys(chapterInfo.levels).map((key) => {
                return (
                  <LevelRow key={key} levelInfo={chapterInfo.levels[key]} />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
};
const Content = observer(({ store }: { store: GameGeometryStore }) => {
  return (
    <Stack direction="column" spacing={6}>
      <GameStatusGeometry hideEntryButton={true} />
      {Object.keys(store.chapters).map((key) => {
        const chapterInfo = store.chapters[key];
        return <LevelTable key={key} chapterInfo={toJS(chapterInfo)} />;
      })}
    </Stack>
  );
});

const GeometryDetail = () => {
  const { gameGeometry } = useCurrentStudentStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    gameGeometry.update().catch((error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Content store={gameGeometry} />;
};

export default GeometryDetail;
