import { styled } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import {
  Breadcrumbs,
  Container,
  Typography,
  Link as MLink,
} from "@mui/material";
import Page from "../components/Page";
import { useStudentStore } from "../stores/useStore";
import StudentStoreContext from "../sections/@studentDetail/studentContext";
import StudentStore from "../stores/studentStore";
import HanoiDetail from "../sections/@gameDetail/hanoiDetail";
import NimDetail from "../sections/@gameDetail/nimDetail";
import GeometryDetail from "../sections/@gameDetail/geometryDetail";

const Title = styled("h2")(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const BreadcrumbsStyle = styled(Breadcrumbs)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

function GameSpecificContent({ gameId }: { gameId: string }) {
  const navigate = useNavigate();

  switch (gameId) {
    case "hanoi":
      return <HanoiDetail />;
    case "nim":
      return <NimDetail />;
    case "geometry":
      return <GeometryDetail />;
    default:
      navigate("/404");
      return <div />;
  }
}

const Content = observer(
  ({
    store,
    gameTitle,
    gameId,
  }: {
    gameTitle: string;
    gameId: string;
    store: StudentStore;
  }) => {
    return (
      <Page title={`游戏详情 - ${store.username} - ${gameTitle}`}>
        <StudentStoreContext.Provider value={store}>
          <Container maxWidth="xl">
            <Title>{gameTitle}</Title>
            <BreadcrumbsStyle>
              <MLink
                component={Link}
                underline="hover"
                color="inherit"
                to={`/dashboard/student/${store.id}`}
              >
                学生 - {store.username}
              </MLink>
              <Typography color="text.primary">{gameTitle}</Typography>
            </BreadcrumbsStyle>
            <GameSpecificContent gameId={gameId} />
          </Container>
        </StudentStoreContext.Provider>
      </Page>
    );
  }
);

const GameDetail = () => {
  const { studentId, gameId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const store = useStudentStore(studentId ?? "");
  useEffect(() => {
    store.update().catch((error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!studentId || !gameId) {
    navigate("/404");
    return null;
  }

  const gameTitle = (() => {
    switch (gameId) {
      case "hanoi":
        return "汉诺塔";
      case "nim":
        return "尼姆游戏";
      case "geometry":
        return "几何迷踪";
      default:
        return "";
    }
  })();

  return <Content gameTitle={gameTitle} gameId={gameId} store={store} />;
};

export default GameDetail;
