import { makeObservable, observable, action } from "mobx";
import { get, set, rm } from "lockr";
import { login } from "../services/authServices";
import CurrentUserStore from "./currentUserStore";

const REFRESH_TOKEN_KEY = "refreshToken";
const TOKEN_KEY = "token";

export default class LoginStateStore {
  currentUserStore: CurrentUserStore;
  isLoggedIn: boolean = false;
  remember: boolean = false;
  token: string = "";
  refreshToken: string = "";

  constructor(currentUserStore: CurrentUserStore) {
    this.currentUserStore = currentUserStore;
    const storedToken = get(TOKEN_KEY) as string;
    const storedRefreshToken = get(TOKEN_KEY) as string;
    this.token = storedToken;
    this.refreshToken = storedRefreshToken;
    if (storedToken) {
      this.isLoggedIn = true;
    }
    makeObservable(this, {
      isLoggedIn: observable,
      logout: action,
      receiveToken: action,
    });
  }

  logout() {
    this.isLoggedIn = false;
    this.currentUserStore.clear();
    rm("token");
    rm("refreshToken");
  }

  async login(username: string, password: string, remember: boolean) {
    const result = await login({ username, password });
    this.remember = remember;
    this.receiveToken({ ...result, remember });
    this.currentUserStore.refresh();
  }

  receiveToken({
    token,
    refreshToken,
    remember,
  }: {
    token: string;
    refreshToken: string;
    remember: boolean;
  }) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.isLoggedIn = true;
    if (remember) {
      set(TOKEN_KEY, token);
      set(REFRESH_TOKEN_KEY, refreshToken);
    }
  }
}
