import http from "../utils/axios";
// import mock_students from "../_mocks_/students";

export type StudentsListService = (args: {
  page: number;
  pageSize: number;
  nameFilter?: string;
  sortBy?: string;
  sortOrder?: "desc" | "asc";
}) => Promise<{
  list: {
    id: string;
    username: string;
    email: string;
    lastLoginTime: string;
    activated: boolean;
  }[];
  totalCount: number;
  pageCount: number;
  page: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}>;

// const mock_studentsListService: StudentsListService = async ({
//   page,
//   pageSize,
// }: {
//   page: number;
//   pageSize: number;
// }) => {
//   const students = mock_students;
//   const start = page * pageSize;
//   const end = start + pageSize;
//   return {
//     students: students.slice(start, end),
//     maxPage: Math.ceil(students.length / pageSize),
//     maxCount: students.length,
//   };
// };

const live_studentsListService: StudentsListService = async ({
  page,
  pageSize,
  nameFilter,
  sortBy,
  sortOrder,
}: {
  page: number;
  pageSize: number;
  nameFilter?: string;
  sortBy?: string;
  sortOrder?: "desc" | "asc";
}) => {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      list: {
        id: string;
        username: string;
        email: string;
        lastLoginTime: string;
        activated: boolean;
      }[];
      totalCount: number;
      pageCount: number;
      page: number;
      hasNextPage: boolean;
      hasPrevPage: boolean;
    };
  }>(`/api/students/list/${page}`, {
    params: {
      pageSize,
      nameFilter,
      sortBy,
      sortOrder,
    },
  });

  if (res.data.error) {
    throw new Error(res.data.message);
  }
  return {
    list: res.data.data?.list ?? [],
    totalCount: res.data.data?.totalCount ?? 0,
    pageCount: res.data.data?.pageCount ?? 0,
    page,
    hasNextPage: res.data.data?.hasNextPage ?? false,
    hasPrevPage: res.data.data?.hasPrevPage ?? false,
  };
};

const service = live_studentsListService;

export default service;
