import { observer } from "mobx-react-lite";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
// components
import Page from "../components/Page";
import Scrollbar from "../components/Scrollbar";
import Iconify from "../components/Iconify";
import SearchNotFound from "../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user";
import { useLoginStateStore, useUserListStore } from "../stores/useStore";
import CurrentUserStore from "../stores/currentUserStore";
import { UserRole } from "../stores/userStore";
import UserListStore from "../stores/userListStore";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "username", label: "用户名", alignRight: false },
  { id: "name", label: "姓名", alignRight: false },
  { id: "role", label: "角色", alignRight: false },
  { id: "email", label: "邮箱", alignRight: false },
  { id: "" },
];

// ----------------------------------------------------------------------

const UserTable = observer(({ store }: { store: UserListStore }) => {
  const { enqueueSnackbar } = useSnackbar();
  const emptyRows =
    store.page > 0
      ? Math.max(0, (1 + store.page) * store.rowsPerPage - store.maxCount)
      : 0;
  return (
    <Card>
      <UserListToolbar
        numSelected={store.selectedUsers.size}
        filterName={store.filterName}
        onFilterName={(event) => {
          store.updateFilter(event.target.value);
        }}
      />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={store.order}
              orderBy={store.orderBy}
              headLabel={TABLE_HEAD}
              rowCount={store.maxCount}
              numSelected={store.selectedUsers.size}
              onRequestSort={(event, property) => {
                const isAsc =
                  store.orderBy === property && store.order === "asc";
                store.updateOrder(isAsc ? "desc" : "asc", property);
              }}
              onSelectAllClick={(event) => {
                if (event.target.checked) {
                  store.selectAllAtCurrentPage();
                  return;
                }
                store.unselectAll();
              }}
            />
            <TableBody>
              {store.currentPage.users.map((row) => {
                const { id, name, role, username, email } = row;
                const isItemSelected = store.selectedUsers.has(id);

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell component="th" scope="row" padding="normal">
                      <span>{username}</span>
                    </TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">
                      {displayNameForRole(role)}
                    </TableCell>
                    <TableCell align="left">{email}</TableCell>

                    <TableCell align="right">
                      <UserMoreMenu
                        userStore={row}
                        onDeleteButtonClick={async () => {
                          try {
                            await store.deleteUser(id);
                            enqueueSnackbar("用户已删除", {
                              variant: "success",
                            });
                          } catch (error) {
                            if (error instanceof Error) {
                              enqueueSnackbar(error.message, {
                                variant: "error",
                              });
                              return;
                            }
                            enqueueSnackbar(`${error}`, { variant: "error" });
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {store.isEmpty && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={store.filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={store.maxCount}
        rowsPerPage={store.rowsPerPage}
        page={store.page}
        labelRowsPerPage="每页行数"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
        onPageChange={(event, newPage) => {
          store.loadPage(newPage);
        }}
        onRowsPerPageChange={(event) => {
          store.updatePagePerRow(parseInt(event.target.value, 10));
        }}
      />
    </Card>
  );
});

const Content = observer(
  ({
    currentUser,
    store,
  }: {
    currentUser: CurrentUserStore;
    store: UserListStore;
  }) => {
    return (
      <Page title="用户 | 数学嘉年华">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              用户
            </Typography>
            {currentUser.role === UserRole.ADMIN ? (
              <Button
                variant="contained"
                component={RouterLink}
                to="/dashboard/createUser"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                新建用户
              </Button>
            ) : null}
          </Stack>

          <UserTable store={store} />
        </Container>
      </Page>
    );
  }
);

function UserList() {
  const currentUser = useLoginStateStore().currentUserStore;
  const store = useUserListStore();
  useEffect(() => {
    store.refreshCurrentPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Content currentUser={currentUser} store={store} />;
}

function displayNameForRole(role: UserRole): string {
  switch (role) {
    case UserRole.ADMIN:
      return "管理员";
    case UserRole.USER:
      return "教师";
    default:
      return "未知";
  }
}

export default UserList;
