import { observer } from "mobx-react-lite";
import {
  Card,
  Table,
  TableHead,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import { useCurrentStudentStore } from "../../stores/useStore";
import GameStatusNim from "../../sections/@studentDetail/gameStatusNim";
import { useEffect } from "react";
import { fDuration, fPercent } from "../../utils/formatNumber";
import { useSnackbar } from "notistack";
import GameNimStore from "../../stores/gameNimStore";

const TABLE_HEAD = [
  { id: "title", label: "关卡", alignRight: false },
  { id: "lastPlayTime", label: "最近挑战时间", alignRight: false },
  { id: "playCount", label: "挑战次数", alignRight: false },
  { id: "duration", label: "平均挑战时长", alignRight: false },
  { id: "winRate", label: "胜率", alignRight: false },
];

const Head = () => {
  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const LevelRow = ({
  titleLeading,
  levelInfo,
}: {
  titleLeading: string;
  levelInfo: {
    title: string;
    playCount: number;
    winRate?: number;
    averageDuration?: number;
    lastPlayTime?: Date;
  };
}) => {
  return (
    <TableRow hover key={levelInfo.title} tabIndex={-1}>
      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {`${titleLeading} - ${levelInfo.title}`}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">
        {levelInfo.lastPlayTime?.toLocaleString() ?? "--"}
      </TableCell>
      <TableCell align="left">{levelInfo.playCount}</TableCell>
      <TableCell align="left">
        {levelInfo.averageDuration != undefined
          ? fDuration(levelInfo.averageDuration / 1000)
          : "--"}
      </TableCell>
      <TableCell align="left">
        {levelInfo.winRate != undefined ? fPercent(levelInfo.winRate * 100) : "--"}
      </TableCell>
    </TableRow>
  );
};

const LevelTable = observer(({ store }: { store: GameNimStore }) => {
  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <Head />
            <TableBody>
              {store.singleLineState.map((level) => (
                <LevelRow
                  key={`d${level.title}`}
                  titleLeading="单行"
                  levelInfo={level}
                />
              ))}
              {store.multipleLineState.map((level) => (
                <LevelRow
                  key={`m${level.title}`}
                  titleLeading="多行"
                  levelInfo={level}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
});

const Content = observer(({ store }: { store: GameNimStore }) => {
  return (
    <Stack direction="column" spacing={6}>
      <GameStatusNim hideEntryButton={true} />
      <LevelTable store={store} />
    </Stack>
  );
});

const NimDetail = () => {
  const { gameNim } = useCurrentStudentStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    gameNim.update().catch((error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Content store={gameNim} />;
};

export default NimDetail;
