import { styled } from "@mui/material";
import { Grid, Container, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import Page from "../components/Page";
import { useStudentStore } from "../stores/useStore";
import StudentStoreContext from "../sections/@studentDetail/studentContext";
// sections
import StudentInfo from "../sections/@studentDetail/studentInfo";
import GameStatusHanoi from "../sections/@studentDetail/gameStatusHanoi";
import GameStatusNim from "../sections/@studentDetail/gameStatusNim";
import GameStatusGeometry from "../sections/@studentDetail/gameStatusGeometry";
import StudentRecentActivity from "../sections/@studentDetail/studentRecentActivity";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import StudentStore from "../stores/studentStore";

const Title = styled("h2")(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const Content = observer(({ store }: { store: StudentStore }) => {
  return (
    <Page title={`学生详情 - ${store.username}`}>
      <StudentStoreContext.Provider value={store}>
        <Container maxWidth="xl">
          <Title>学生信息</Title>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <Stack direction="column" spacing={3}>
                <StudentInfo />
                <StudentRecentActivity />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <Stack direction="column" spacing={3}>
                <GameStatusGeometry />
                <GameStatusHanoi />
                <GameStatusNim />
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </StudentStoreContext.Provider>
    </Page>
  );
});

const StudentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const store = useStudentStore(id ?? "");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    Promise.all([store.update(), store.updateGames()]).catch((error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!id) {
    navigate("/404");
    return null;
  }

  return <Content store={store} />;
};

export default StudentDetail;
