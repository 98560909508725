import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";

import service from "../services/studentListServices";
import {
  addStudentService,
  deleteStudentService,
} from "../services/studentUpdateService";
import StudentStore from "./studentStore";

class Page {
  index: number;
  students: StudentStore[];

  constructor(index: number, students: StudentStore[]) {
    this.index = index;
    this.students = students;
    makeObservable(this, {
      index: observable,
      students: observable,
    });
  }

  refresh() {
    this.students = [];
  }
}

export default class StudentListStore {
  currentPage: Page = new Page(0, []);
  maxPage: number = 0;
  maxCount: number = 0;
  rowsPerPage: number = 5;
  selectedStudents: Set<string> = new Set();
  order: "asc" | "desc" = "desc";
  orderBy: string = "default";
  studentsListService = service;

  get page() {
    return this.currentPage.index;
  }

  get isEmpty() {
    return this.maxPage === 0;
  }

  constructor() {
    makeObservable(this, {
      maxPage: observable,
      maxCount: observable,
      currentPage: observable,
      rowsPerPage: observable,
      selectedStudents: observable,
      order: observable,
      orderBy: observable,

      page: computed,
      isEmpty: computed,

      updateOrder: action,
      loadPage: action,
      selectAllAtCurrentPage: action,
      unselectAll: action,
      toggleSelection: action,
      updatePagePerRow: action,
    });
  }

  refreshCurrentPage() {
    this.loadPage(this.page);
  }

  nextPage() {
    this.loadPage(this.page + 1);
  }

  previousPage() {
    this.loadPage(this.page - 1);
  }

  updateOrder(order: "asc" | "desc", orderBy: string) {
    this.order = order;
    this.orderBy = orderBy;
    this.refreshCurrentPage();
  }

  updatePagePerRow(rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
    this.loadPage(0);
  }

  loadPage(index: number) {
    this.studentsListService({
      page: index,
      pageSize: this.rowsPerPage,
    }).then(({ list, pageCount, totalCount }) => {
      runInAction(() => {
        const newPage = new Page(
          index,
          list.map(
            (student) =>
              new StudentStore({
                id: student.id,
                username: student.username,
                email: student.email,
                lastLoginTime: new Date(student.lastLoginTime),
                isVerified: student.activated,
              })
          )
        );
        this.currentPage = newPage;
        this.maxPage = pageCount;
        this.maxCount = totalCount;
      });
    });
  }

  selectAllAtCurrentPage() {
    this.selectedStudents = new Set(
      this.currentPage.students.map((student) => student.id)
    );
  }

  unselectAll() {
    this.selectedStudents = new Set();
  }

  toggleSelection(studentId: string) {
    if (this.selectedStudents.has(studentId)) {
      this.selectedStudents.delete(studentId);
      return;
    }
    this.selectedStudents.add(studentId);
  }

  async addStudent(username: string) {
    await addStudentService({ username });
    this.refreshCurrentPage();
  }

  async deleteStudent(id: string) {
    await deleteStudentService({ id });
    this.refreshCurrentPage();
  }
}
