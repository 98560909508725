import { observer } from "mobx-react-lite";
// material
import { styled } from "@mui/material/styles";
import { Card, Typography, Stack, Container } from "@mui/material";
import Iconify from "../../components/Iconify";
import { useMemo, useState } from "react";
import { useCurrentStudentStore } from "../../stores/useStore";
import { Link } from "react-router-dom";
import StudentStore from "../../stores/studentStore";

// ----------------------------------------------------------------------

const RootStyle = (color: string) =>
  styled(Card)(({ theme }) => ({
    boxShadow: theme.createCustomShadows(color, 0.3).z4,
    textAlign: "center",
    color: "white",
    backgroundColor: color,
    "&:hover": {
      boxShadow: theme.createCustomShadows(color, 0.6).z12,
    },
  }));

const Content = styled(Container)(({ theme }) => ({
  padding: theme.spacing(3, 3),
  textAlign: "left",
}));

const TitleStyle = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(1),
}));

const LastPlayTimeStyle = styled(Stack)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing(2),
}));

const CornerAreaStyle = styled(Stack)(({ theme }) => ({
  position: "absolute",
  top: 12,
  right: 12,
  "& > *": {
    transition: "all 0.3s ease-in-out",
  },
}));

const DetailButtonStyle = styled(Card)(({ theme }) => ({
  boxShadow: theme.customShadows.z1,
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  color: "white",
  fontSize: "15px",
  fontWeight: "bold",
  "& a": {
    color: "white",
    textDecoration: "none",
  },
}));

const Panel = observer(
  ({
    gameId,
    title,
    children,
    themeColor,
    cornerWidget,
    hideEntryButton,
    lastPlayTime,
    studentStore,
  }: {
    hideEntryButton: boolean;
    gameId: string;
    title: string;
    themeColor: string;
    lastPlayTime?: Date;
    children: React.ReactNode;
    cornerWidget: React.ReactNode;
    studentStore: StudentStore;
  }) => {
    const [mouseOver, setMouseOver] = useState(false);
    const Root = useMemo(() => RootStyle(themeColor), [themeColor]);
    return (
      <Root
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <CornerAreaStyle direction="row" spacing={0} alignItems="center">
          {cornerWidget}
          {hideEntryButton ? null : (
            <DetailButtonStyle>
              <Link
                to={`/dashboard/student/${studentStore.id}/game/${gameId}`}
                color="primary"
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  {mouseOver ? <span>查看详情</span> : null}
                  <Iconify
                    width="20px"
                    height="20px"
                    icon="eva:arrow-circle-right-fill"
                  />
                </Stack>
              </Link>
            </DetailButtonStyle>
          )}
        </CornerAreaStyle>

        <Content>
          <TitleStyle variant="h4">{title}</TitleStyle>
          <LastPlayTimeStyle direction="row" alignItems="center" spacing={1}>
            <Iconify icon="eva:clock-fill" />
            <Typography variant="subtitle1">
              最近游玩时间：{lastPlayTime?.toLocaleString() ?? "--"}
            </Typography>
          </LastPlayTimeStyle>
          {children}
        </Content>
      </Root>
    );
  }
);

const GameStatusPanel = ({
  gameId,
  title,
  children,
  themeColor,
  cornerWidget,
  hideEntryButton,
  lastPlayTime,
}: {
  hideEntryButton: boolean;
  gameId: string;
  title: string;
  themeColor: string;
  lastPlayTime?: Date;
  children: React.ReactNode;
  cornerWidget: React.ReactNode;
}) => {
  const studentStore = useCurrentStudentStore();

  return (
    <Panel
      studentStore={studentStore}
      gameId={gameId}
      title={title}
      themeColor={themeColor}
      cornerWidget={cornerWidget}
      hideEntryButton={hideEntryButton}
      lastPlayTime={lastPlayTime}
    >
      {children}
    </Panel>
  );
};

export default GameStatusPanel;
