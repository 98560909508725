import { action, makeObservable, observable } from "mobx";
import { get, set, rm } from "lockr";
import { fetchCurrentUserDetail } from "../services/userDetailService";
import { UserRole } from "./userStore";

enum UserDetailKey {
  USERNAME = "UserDetail:username",
  EMAIL = "UserDetail:email",
  ID = "UserDetail:id",
  NAME = "UserDetail:name",
  ROLE = "UserDetail:role",
}

export default class UserStore {
  id: string = "";
  name: string = "";
  username: string = "";
  email: string = "";
  role: UserRole = UserRole.USER;

  constructor() {
    makeObservable(this, {
      id: observable,
      name: observable,
      username: observable,
      email: observable,
      role: observable,

      refresh: action,
    });

    this.loadFromStorage();
  }

  clear() {
    this.id = "";
    this.name = "";
    this.username = "";
    this.email = "";
    this.role = UserRole.USER;
    rm(UserDetailKey.ID);
    rm(UserDetailKey.NAME);
    rm(UserDetailKey.USERNAME);
    rm(UserDetailKey.EMAIL);
    rm(UserDetailKey.ROLE);
  }

  refresh() {
    fetchCurrentUserDetail().then(({ id, name, username, email, role }) => {
      this.id = id;
      this.name = name;
      this.username = username;
      this.email = email;
      this.role = role as UserRole;
      this.storeInStorage();
    });
  }

  loadFromStorage() {
    this.id = get(UserDetailKey.ID, "");
    this.name = get(UserDetailKey.NAME, "");
    this.username = get(UserDetailKey.USERNAME, "");
    this.email = get(UserDetailKey.EMAIL, "");
    this.role = get(UserDetailKey.ROLE, UserRole.USER);
  }

  storeInStorage() {
    set(UserDetailKey.ID, this.id);
    set(UserDetailKey.NAME, this.name);
    set(UserDetailKey.USERNAME, this.username);
    set(UserDetailKey.EMAIL, this.email);
    set(UserDetailKey.ROLE, this.role);
  }
}
