import { observer } from "mobx-react-lite";
// material
import { styled } from "@mui/material/styles";
import { Typography, Stack, Grid } from "@mui/material";
import Iconify from "../../components/Iconify";
import GameStatusPanel from "./gameStatusPanel";
import { useCurrentStudentStore } from "../../stores/useStore";
import GameHanoiStore from "../../stores/gameHanoiStore";

// ----------------------------------------------------------------------

const ProgressTextStyle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

const LevelProgressStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  minWidth: 60,
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
}));

const LevelProgress = ({
  title,
  completed,
}: {
  title: string;
  completed: boolean;
}) => {
  return (
    <Grid item xs="auto">
      <LevelProgressStyle alignItems="center">
        <Typography variant="subtitle1">{title}</Typography>
        {completed ? (
          <Iconify
            sx={{ fontSize: "1.3rem", color: "yellow" }}
            icon="eva:checkmark-square-2-fill"
          />
        ) : (
          <Iconify sx={{ fontSize: "1.3rem" }} icon="eva:square-fill" />
        )}
      </LevelProgressStyle>
    </Grid>
  );
};

const Content = observer(
  ({ hideEntryButton, gameHanoi }: { hideEntryButton: boolean, gameHanoi: GameHanoiStore }) => {
    
    return (
      <GameStatusPanel
        gameId="hanoi"
        title="汉诺塔"
        themeColor="#E44B6D"
        lastPlayTime={gameHanoi.lastPlayTime}
        hideEntryButton={hideEntryButton}
        cornerWidget={
          <ProgressTextStyle variant="subtitle1">
            进度：{gameHanoi.progress}
          </ProgressTextStyle>
        }
      >
        <Grid container rowSpacing={1.2} columnSpacing={2}>
          {gameHanoi.levelState.map((level) => {
            return <LevelProgress key={level.title} title={level.title} completed={level.isFinished} />
          })}
        </Grid>
      </GameStatusPanel>
    );
  }
);

const GameStatusHanoi = ({ hideEntryButton = false }: { hideEntryButton?: boolean }) => {
  const { gameHanoi } = useCurrentStudentStore();
  return <Content hideEntryButton={hideEntryButton} gameHanoi={gameHanoi} />;
};

export default GameStatusHanoi;
