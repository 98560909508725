import http from "../utils/axios";

export type GameHanoiLevelState = {
  isFinished: boolean;
  finishCount: number;
  submitCount: number;
  lastPlayTime?: Date;
  averageFinishDuration?: number;
  averageStep?: number;
  levelId: string;
};

export type GameHanoiState = {
  lastPlayTime?: Date;
  levels: GameHanoiLevelState[];
};

// async function mock_gameHanoiStatusService(args: { studentId: string }): Promise<GameHanoiStatus> {
//   return {
//     lastPlayTime: new Date(),
//     levelStatus: [
//       {
//         title: "1层",
//         isFinished: true,
//         lastPlayTime: new Date(),
//         averageDuration: 30,
//         submitCount: 30,
//         averageStep: 2,
//       },
//       {
//         title: "2层",
//         isFinished: true,
//         lastPlayTime: new Date(),
//         averageDuration: 30,
//         submitCount: 10,
//         averageStep: 10,
//       },
//       {
//         title: "3层",
//         isFinished: false,
//         lastPlayTime: undefined,
//         averageDuration: undefined,
//         submitCount: 20,
//         averageStep: undefined,
//       },
//       {
//         title: "4层",
//         isFinished: false,
//         lastPlayTime: undefined,
//         averageDuration: undefined,
//         submitCount: 0,
//         averageStep: undefined,
//       },
//       {
//         title: "5层",
//         isFinished: false,
//         lastPlayTime: undefined,
//         averageDuration: undefined,
//         submitCount: 0,
//         averageStep: undefined,
//       },
//       {
//         title: "N层",
//         isFinished: false,
//         lastPlayTime: undefined,
//         averageDuration: undefined,
//         submitCount: 0,
//         averageStep: undefined,
//       },
//       {
//         title: "汉诺塔解析",
//         isFinished: false,
//         lastPlayTime: undefined,
//         averageDuration: undefined,
//         submitCount: 0,
//         averageStep: undefined,
//       },
//     ],
//   };
// }

async function live_gameHanoiStateService(args: {
  studentId: string;
}): Promise<GameHanoiState> {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      lastPlayTime?: string;
      levels: {
        [key: string]: {
          isFinished: boolean;
          finishCount: number;
          submitCount: number;
          lastPlayTime?: Date;
          averageFinishDuration?: number;
          averageStep?: number;
        };
      };
    };
  }>(`/api/students/detail/${args.studentId}/game/hanoi`);

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return {
    lastPlayTime: (() => {
      if (res.data.data?.lastPlayTime) {
        return new Date(res.data.data.lastPlayTime);
      }
      return undefined;
    })(),
    levels: (() => {
      const all: GameHanoiLevelState[] = [];
      for (const [key, value] of Object.entries(res.data.data?.levels ?? {})) {
        all.push({
          isFinished: value.isFinished,
          finishCount: value.finishCount,
          submitCount: value.submitCount,
          lastPlayTime: value.lastPlayTime
            ? new Date(value.lastPlayTime)
            : undefined,
          averageFinishDuration: value.averageFinishDuration,
          averageStep: value.averageStep,
          levelId: key,
        });
      }
      return all;
    })(),
  };
}

export default live_gameHanoiStateService;
