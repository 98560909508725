// import mock_users from "../_mocks_/user";
import http from "../utils/axios";

export type UserListService = (args: {
  page: number;
  pageSize: number;
  nameFilter?: string;
  sortBy?: string;
  sortOrder?: "desc" | "asc";
}) => Promise<{
  users: {
    id: string;
    name: string;
    username: string;
    email: string;
    role: string;
  }[];
  totalCount: number;
  pageCount: number;
  page: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
}>;

const live_userListService: UserListService = async ({
  page,
  pageSize,
  nameFilter,
  sortBy,
  sortOrder,
}: {
  page: number;
  pageSize: number;
  nameFilter?: string;
  sortBy?: string;
  sortOrder?: 'desc' | 'asc';
}) => {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      list: {
        id: string;
        username: string;
        email: string;
        name: string;
        role: string;
      }[];
      totalCount: number;
      pageCount: number;
      page: number;
      hasNextPage: boolean;
      hasPrevPage: boolean;
    };
  }>(`/api/users/list/${page}`, {
    params: {
      pageSize,
      name: nameFilter && nameFilter.length > 0 ? nameFilter : undefined,
      sortBy,
      sortOrder,
    },
  });

  if (res.data.error) {
    throw new Error(res.data.message);
  }
  return {
    users: res.data.data?.list ?? [],
    totalCount: res.data.data?.totalCount ?? 0,
    pageCount: res.data.data?.pageCount ?? 0,
    page,
    hasNextPage: res.data.data?.hasNextPage ?? false,
    hasPrevPage: res.data.data?.hasPrevPage ?? false,
  };
};


// export const mock_userListService: UserListService = async ({
//   page,
//   pageSize,
// }: {
//   page: number;
//   pageSize: number;
// }) => {
//   const user = mock_users;
//   const start = page * pageSize;
//   const end = start + pageSize;
//   return {
//     users: user.slice(start, end),
//     maxPage: Math.ceil(user.length / pageSize),
//     maxCount: user.length,
//   };
// };

const service = live_userListService;

export default service;
