import { ReactNode } from "react";
import { SnackbarProvider } from "notistack";

export default function IntegrationNotistack({
  children,
}: {
  children: ReactNode;
}) {
  return <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>;
}
