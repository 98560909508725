import { useMemo } from "react";
// material
import { CssBaseline } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
} from "@mui/material/styles";
//
import palette from "./palette";
import typography from "./typography";
import componentsOverride from "./overrides";
import shadows, { customShadows, createCustomShadow } from "./shadows";

// ----------------------------------------------------------------------

export default function ThemeConfig({
  children,
}: {
  children: React.ReactNode;
}) {
  const themeOptions = useMemo(
    () =>
      ({
        palette,
        shape: { borderRadius: 8 },
        typography,
        customShadows,
        shadows,
      } as ThemeOptions),
    []
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  theme.createCustomShadows = createCustomShadow;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
