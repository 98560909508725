// material
import { styled } from "@mui/material/styles";
import { Stack, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import CreateUserForm from "../sections/@createUser/createUserForm";

// ----------------------------------------------------------------------

const FormWrapper = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 600,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

// ----------------------------------------------------------------------

export default function CreateUser() {
  return (
    <Page title="创建用户 | 数学嘉年华">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            创建新用户
          </Typography>
        </Stack>
        <FormWrapper>
          <CreateUserForm />
        </FormWrapper>
      </Container>
    </Page>
  );
}
