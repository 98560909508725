import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";

import service from "../services/userListService";
import deleteUserService from "../services/deleteUserService";
import UserStore, { UserRole } from "./userStore";

class Page {
  index: number;
  users: UserStore[];

  constructor(index: number, users: UserStore[]) {
    this.index = index;
    this.users = users;
    makeObservable(this, {
      index: observable,
      users: observable,
    });
  }

  refresh() {
    this.users = [];
  }
}

export default class UserListStore {
  currentPage: Page = new Page(0, []);
  maxPage: number = 0;
  maxCount: number = 0;
  rowsPerPage: number = 5;
  selectedUsers: Set<string> = new Set();
  order: "asc" | "desc" = "desc";
  orderBy: string = "default";
  usersListService = service;
  filterName: string = "";

  get page() {
    return this.currentPage.index;
  }

  get isEmpty() {
    return this.maxPage === 0;
  }

  constructor() {
    makeObservable(this, {
      maxPage: observable,
      maxCount: observable,
      currentPage: observable,
      rowsPerPage: observable,
      selectedUsers: observable,
      order: observable,
      orderBy: observable,
      filterName: observable,

      page: computed,
      isEmpty: computed,

      updateOrder: action,
      loadPage: action,
      selectAllAtCurrentPage: action,
      unselectAll: action,
      toggleSelection: action,
      updatePagePerRow: action,
    });
  }

  refreshCurrentPage() {
    this.loadPage(this.page);
  }

  nextPage() {
    this.loadPage(this.page + 1);
  }

  previousPage() {
    this.loadPage(this.page - 1);
  }

  updateOrder(order: "asc" | "desc", orderBy: string) {
    this.order = order;
    this.orderBy = orderBy;
    this.refreshCurrentPage();
  }

  updatePagePerRow(rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
    this.loadPage(0);
  }

  updateFilter(filterName: string) {
    this.filterName = filterName;
    this.loadPage(0);
  }

  loadPage(index: number) {
    this.usersListService({
      page: index,
      pageSize: this.rowsPerPage,
      nameFilter: this.filterName,
    }).then(({ users, pageCount, totalCount }) => {
      runInAction(() => {
        const newPage = new Page(
          index,
          users.map(
            (user) =>
              new UserStore({
                id: user.id,
                name: user.name,
                username: user.username,
                email: user.email,
                role: user.role as UserRole,
              })
          )
        );
        this.currentPage = newPage;
        this.maxPage = pageCount;
        this.maxCount = totalCount;
      });
    });
  }

  selectAllAtCurrentPage() {
    this.selectedUsers = new Set(this.currentPage.users.map((user) => user.id));
  }

  unselectAll() {
    this.selectedUsers = new Set();
  }

  toggleSelection(userId: string) {
    if (this.selectedUsers.has(userId)) {
      this.selectedUsers.delete(userId);
      return;
    }
    this.selectedUsers.add(userId);
  }

  async deleteUser(id: string) {
    await deleteUserService({ userId: id });
    this.refreshCurrentPage();
  }
}
