import Axios from "axios";
import { useAppStore } from "../stores/useStore";

const http = Axios.create({
  headers: {
    Accepts: "application/json",
  },
  validateStatus: (code) => code !== 401,
});

http.interceptors.request.use(
  async (config) => {
    const store = useAppStore().loginStateStore;
    config.headers = {
      Authorization: `Bearer ${store.token}`,
      Accept: "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      const store = useAppStore();
      store.logout();
      window.location.href = "/login";
    }
  }
);

export default http;
