import http from "../utils/axios";

export default async function deleteUserService(args: {
  userId: string;
}): Promise<void> {
  const res = await http.delete<{
    error: boolean;
    message?: string;
  }>(`/api/users/detail/${args.userId}`);

  if (res.data.error) {
    throw new Error(res.data.message);
  }
  return;
}
