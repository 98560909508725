import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import { Stack, TextField, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useLoginStateStore } from "../../stores/useStore";
import EditUserStore from "../../stores/editUserStore";
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

export default function ChangePasswordForm() {
  const currentUserStore = useLoginStateStore().currentUserStore;
  const [store] = useState(new EditUserStore(currentUserStore));
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(4, "密码太短")
      .max(50, "密码太长")
      .required("请输入旧密码"),
    newPassword: Yup.string()
      .min(4, "密码太短")
      .max(50, "密码太长")
      .required("请输入新密码"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "两次密码不一致")
      .required("请再次输入新密码"),
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async ({ oldPassword, newPassword }) => {
      try {
        await store.changePassword({ oldPassword, newPassword });
        navigate("/dashboard/app", { replace: true });
        enqueueSnackbar("密码已修改", { variant: "success" });
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
          return;
        }
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="旧密码"
            {...getFieldProps("oldPassword")}
            error={Boolean(touched.oldPassword && errors.oldPassword)}
            helperText={touched.oldPassword && errors.oldPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword((show) => !show);
                    }}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            autoComplete="new-password"
            type={showNewPassword ? "text" : "password"}
            label="新密码"
            {...getFieldProps("newPassword")}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowNewPassword((show) => !show);
                    }}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            autoComplete="new-password"
            type={showConfirmPassword ? "text" : "password"}
            label="重复密码"
            {...getFieldProps("confirmPassword")}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowConfirmPassword((show) => !show);
                    }}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        showConfirmPassword
                          ? "eva:eye-fill"
                          : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            修改
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
