import { makeObservable, observable, runInAction } from "mobx";
import GameHanoiStore from "./gameHanoiStore";
import GameNimStore from "./gameNimStore";
import GameGeometryStore from "./gameGeometryStore";
import studentDetailService from "../services/studentDetailService";

export default class StudentStore {
  id: string;
  username: string;
  email: string;
  lastLoginTime?: Date;
  isVerified: boolean;

  gameHanoi: GameHanoiStore;
  gameNim: GameNimStore;
  gameGeometry: GameGeometryStore

  constructor({
    id,
    username = "",
    email = "",
    lastLoginTime,
    isVerified = false,
  }: {
    id: string;
    username?: string;
    email?: string;
    lastLoginTime?: Date;
    isVerified?: boolean;
  }) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.lastLoginTime = lastLoginTime;
    this.isVerified = isVerified;
    this.gameHanoi = new GameHanoiStore(id);
    this.gameNim = new GameNimStore(id);
    this.gameGeometry = new GameGeometryStore(id);

    makeObservable(this, {
      id: observable,
      username: observable,
      email: observable,
      lastLoginTime: observable,
      isVerified: observable,
    });
  }

  async update() {
    const { id, username, email, lastLoginTime, isVerified } =
      await studentDetailService({ studentId: this.id });
    runInAction(() => {
      this.id = id;
      this.username = username;
      this.email = email;
      this.lastLoginTime = lastLoginTime;
      this.isVerified = isVerified;
    });
  }

  async updateGames() {
    await Promise.all([
      this.gameHanoi.update(),
      this.gameNim.update(),
      this.gameGeometry.update(),
    ]);
  }
}
