import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { useNavigate } from "react-router-dom";
// material
import {
  Stack,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CreateUserStore from "../../stores/createUserStore";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { UserRole } from "../../stores/userStore";

// ----------------------------------------------------------------------

export default function CreateUserForm() {
  const [store] = useState(new CreateUserStore());
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const RegisterSchema = Yup.object().shape({
    username: Yup.string()
      .min(2, "用户名太短")
      .max(50, "用户名太长")
      .required("请输入用户名"),
    name: Yup.string()
      .min(2, "姓名太短")
      .max(50, "姓名太长")
      .required("请输入姓名"),
    email: Yup.string().email("请输入格式正确的邮箱").required("请输入邮箱"),
    role: Yup.string().required("请选择角色"),
    password: Yup.string()
      .min(6, "密码太短")
      .max(50, "密码太长")
      .required("请输入密码"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "两次密码不一致")
      .required("请再次输入密码"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      email: "",
      role: UserRole.USER,
      password: "",
      confirmPassword: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: async ({ username, name, email, role, password }) => {
      try {
        await store.createUser({ username, name, email, role, password });
        navigate("/dashboard/user", { replace: true });
        enqueueSnackbar("用户已创建", { variant: "success" });
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
          return;
        }
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="username"
            label="用户名"
            {...getFieldProps("username")}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />

          <TextField
            fullWidth
            label="姓名"
            {...getFieldProps("name")}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            autoComplete="email"
            type="email"
            label="邮箱"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <FormControl>
            <FormLabel>角色：</FormLabel>
            <RadioGroup row {...getFieldProps("role")}>
              <FormControlLabel
                value={UserRole.USER}
                control={<Radio />}
                label="用户"
              />
              <FormControlLabel
                value={UserRole.ADMIN}
                control={<Radio />}
                label="管理员"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            fullWidth
            autoComplete="new-password"
            type="password"
            label="密码"
            {...getFieldProps("password")}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <TextField
            fullWidth
            autoComplete="new-password"
            type="password"
            label="重复密码"
            {...getFieldProps("confirmPassword")}
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            helperText={touched.confirmPassword && errors.confirmPassword}
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            创建
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
