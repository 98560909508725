// async function mock_createUser(args: {
//   username: string;
//   name: string;
//   role: string;
//   email: string;
//   password: string;
// }) {
//   return { id: "123" };
// }

import http from "../utils/axios";

async function live_createUser(args: {
  username: string;
  name: string;
  role: string;
  email: string;
  password: string;
}) {
  const res = await http.post<{
    error: boolean;
    message?: string;
  }>("/api/users/createUser", args);

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return;
}

export const createUser = live_createUser;
