import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Dialog,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// component
import Iconify from "../../../components/Iconify";
import AddStudentForm from "./AddStudentForm";
import EditUserStore from "../../../stores/editUserStore";
import UserStore from "../../../stores/userStore";

// ----------------------------------------------------------------------

export default function UserMoreMenu({
  onDeleteButtonClick,
  userStore,
}: {
  onDeleteButtonClick: () => void;
  userStore: UserStore;
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddStudentFormOpen, setIsAddStudentFormOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={onDeleteButtonClick}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="删除"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="编辑"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        <MenuItem
          onClick={() => setIsAddStudentFormOpen(true)}
          sx={{ color: "text.secondary" }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="添加学生"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>

      <Dialog
        open={isAddStudentFormOpen}
        onClose={() => {
          setIsAddStudentFormOpen(false);
        }}
      >
        <AddStudentForm store={new EditUserStore(userStore)} />
      </Dialog>
    </>
  );
}
