import { action, makeObservable, observable } from "mobx";
import { fetchUserDetail } from "../services/userDetailService";

export enum UserRole {
  ADMIN = "admin",
  USER = "user",
}

export default class UserStore {
  id: string = "";
  name: string = "";
  username: string = "";
  email: string = "";
  role: UserRole = UserRole.USER;

  constructor({
    id,
    name,
    username,
    email,
    role,
  }: {
    id: string;
    name: string;
    username: string;
    email: string;
    role: UserRole;
  }) {
    this.id = id;
    this.name = name;
    this.username = username;
    this.email = email;
    this.role = role;

    makeObservable(this, {
      id: observable,
      name: observable,
      username: observable,
      email: observable,
      role: observable,

      refresh: action,
    });
  }

  refresh() {
    fetchUserDetail({ userId: this.id }).then(
      ({ id, name, username, email, role }) => {
        this.id = id;
        this.name = name;
        this.username = username;
        this.email = email;
        this.role = role as UserRole;
      }
    );
  }
}
