import { makeObservable } from "mobx";
import bulkAddStudentService from "../services/bulkAddStudentService";
import changePasswordService from "../services/changePasswordService";
import UserStore from "./userStore";

export default class EditUserStore {
  userStore: UserStore;

  constructor(userStore: UserStore) {
    this.userStore = userStore;
    makeObservable(this, {});
  }

  async changePassword(args: { oldPassword: string; newPassword: string }) {
    await changePasswordService(args);
  }

  async addStudent(args: { usernameList: string }) {
    return bulkAddStudentService({
      usernameList: args.usernameList,
      userId: this.userStore.id,
    });
  }
}
