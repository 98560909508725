import { observer } from "mobx-react-lite";
// material
import { Box, Grid, Container, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
// components
import Page from "../components/Page";
import {
  StudentCount,
  GameCount,
  MostActiveStudents,
  RegisteredUserCount,
  GamePopularityChart,
  AppWebsiteVisits,
} from "../sections/@dashboard/app";
import UserStore from "../stores/currentUserStore";
import DashboardStore from "../stores/dashboardStore";
import { UserRole } from "../stores/userStore";
import { useDashboardStore, useLoginStateStore } from "../stores/useStore";

// ----------------------------------------------------------------------

const Content = observer(
  ({
    store,
    currentUser,
  }: {
    store: DashboardStore;
    currentUser: UserStore;
  }) => {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
      store.refresh().catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (currentUser.role === UserRole.ADMIN) {
      return (
        <Page title="仪表盘">
          <Container maxWidth="xl">
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">欢迎回来！</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <RegisteredUserCount store={store} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <StudentCount store={store} />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <GameCount store={store} />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits store={store} />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <GamePopularityChart store={store} />
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <MostActiveStudents store={store} />
              </Grid>
            </Grid>
          </Container>
        </Page>
      );
    } else {
      return (
        <Page title="仪表盘">
          <Container maxWidth="xl">
            <Box sx={{ pb: 5 }}>
              <Typography variant="h4">欢迎回来！</Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={6}>
                <StudentCount store={store} />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <GameCount store={store} />
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <MostActiveStudents store={store} />
              </Grid>
            </Grid>
          </Container>
        </Page>
      );
    }
  }
);

export default function DashboardApp() {
  const store = useDashboardStore();
  const currentUser = useLoginStateStore().currentUserStore;
  return <Content store={store} currentUser={currentUser} />;
}
