import { observer } from "mobx-react-lite";
import {
  Card,
  Table,
  TableHead,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
} from "@mui/material";
import Scrollbar from "../../components/Scrollbar";
import { useCurrentStudentStore } from "../../stores/useStore";
import GameStatusHanoi from "../../sections/@studentDetail/gameStatusHanoi";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import GameHanoiStore from "../../stores/gameHanoiStore";
import { fDuration, fNumber } from "../../utils/formatNumber";

const TABLE_HEAD = [
  { id: "title", label: "关卡", alignRight: false },
  { id: "state", label: "状态", alignRight: false },
  { id: "lastPlayTime", label: "最近游玩时间", alignRight: false },
  { id: "duration", label: "平均通关时长", alignRight: false },
  { id: "submitCount", label: "提交次数", alignRight: false },
  { id: "averageStep", label: "平均步数（提交时）", alignRight: false },
];

const Head = () => {
  return (
    <TableHead>
      <TableRow>
        {TABLE_HEAD.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const LevelRow = ({
  levelInfo,
}: {
  levelInfo: {
    title: string;
    isFinished: boolean;
    lastPlayTime?: Date;
    averageDuration?: number;
    submitCount: number;
    averageStep?: number;
  };
}) => {
  return (
    <TableRow hover key={levelInfo.title} tabIndex={-1}>
      <TableCell component="th" scope="row" padding="normal">
        <Stack direction="row" alignItems="center" spacing={2}>
          <Typography variant="subtitle2" noWrap>
            {levelInfo.title}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell align="left">
        {levelInfo.isFinished ? "已完成" : "未完成"}
      </TableCell>
      <TableCell align="left">
        {levelInfo.lastPlayTime?.toLocaleString() ?? "--"}
      </TableCell>
      <TableCell align="left">
        {levelInfo.averageDuration
          ? `${fDuration(levelInfo.averageDuration / 1000)}`
          : "--秒"}
      </TableCell>
      <TableCell align="left">{levelInfo.submitCount}次</TableCell>
      <TableCell align="left">
        {levelInfo.averageStep ? `${fNumber(levelInfo.averageStep)}` : "--"}次
      </TableCell>
    </TableRow>
  );
};

const LevelTable = observer(({ store }: { store: GameHanoiStore }) => {
  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <Head />
            <TableBody>
              {store.levelState.map((level) => (
                <LevelRow levelInfo={level} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
});

const Content = observer(({ store }: { store: GameHanoiStore }) => {
  return (
    <Stack direction="column" spacing={6}>
      <GameStatusHanoi hideEntryButton={true} />
      <LevelTable store={store} />
    </Stack>
  );
});

const HanoiDetail = () => {
  const { gameHanoi } = useCurrentStudentStore();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    gameHanoi.update().catch((error) => {
      enqueueSnackbar(error.message, { variant: "error" });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Content store={gameHanoi} />;
};

export default HanoiDetail;
