import http from "../utils/axios";

// import { self } from '../_mocks_/user';

// async function mock_fetchUserDetail() {
//   return admin;
// }

async function live_fetchCurrentUserDetail() {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      id: string;
      username: string;
      email: string;
      name: string;
      role: string;
    };
  }>("/api/users/detail/self");

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return {
    id: res.data.data?.id ?? "",
    username: res.data.data?.username ?? "",
    email: res.data.data?.email ?? "",
    name: res.data.data?.name ?? "",
    role: res.data.data?.role ?? "user",
  };
}

export const fetchCurrentUserDetail = live_fetchCurrentUserDetail;

async function live_fetchUserDetail(args: { userId: string }) {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      id: string;
      username: string;
      email: string;
      name: string;
      role: string;
    };
  }>(`/api/users/detail/${args.userId}`);

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return {
    id: res.data.data?.id ?? "",
    username: res.data.data?.username ?? "",
    email: res.data.data?.email ?? "",
    name: res.data.data?.name ?? "",
    role: res.data.data?.role ?? "user",
  };
}

export const fetchUserDetail = live_fetchUserDetail;
