import { useContext } from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack } from "@mui/material";
// store
import StudentStoreContext from "./studentContext";
import { observer } from "mobx-react-lite";
import StudentStore from "../../stores/studentStore";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 3),
  fontSize: "1rem",
  boxShadow: theme.customShadows.z12,
}));

// ----------------------------------------------------------------------

const Content = observer(({ store }: { store: StudentStore }) => {
  return (
    <RootStyle>
      <Stack direction="column" spacing={1} alignItems="flex-start">
        <h3>最近登录时间</h3>
        <p>{store.lastLoginTime?.toLocaleString() ?? "--"}</p>
      </Stack>
    </RootStyle>
  );
});

const StudentRecentActivity = observer(() => {
  const store = useContext(StudentStoreContext);
  return <Content store={store} />;
});

export default StudentRecentActivity;
