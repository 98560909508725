import { makeObservable, observable, runInAction } from "mobx";
import dashboardService from "../services/dashboardService";

export default class DashboardStore {
  totalRegisteredUserCount?: number;
  studentCount?: number;
  gameCount?: number;

  gamePopularity: { title: string; count: number; color: string }[] = [];
  mostActiveStudents: {
    id: string;
    username: string;
    lastLoginTime: Date;
  }[] = [];
  recentLoginGraphData: { date: Date; count: number }[] = [];

  constructor() {
    makeObservable(this, {
      totalRegisteredUserCount: observable,
      studentCount: observable,
      gameCount: observable,
      gamePopularity: observable,
      mostActiveStudents: observable,
      recentLoginGraphData: observable,
    });
  }

  async refresh() {
    const {
      totalRegisteredUserCount,
      studentCount,
      gameCount,
      gamePopularity,
      mostActiveStudents,
      recentLoginGraphData,
    } = await dashboardService();
    runInAction(() => {
      this.totalRegisteredUserCount = totalRegisteredUserCount;
      this.studentCount = studentCount;
      this.gameCount = gameCount;
      this.gamePopularity = gamePopularity;
      this.mostActiveStudents = mostActiveStudents;
      this.recentLoginGraphData = recentLoginGraphData;
    });
  }
}
