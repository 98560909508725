import { useContext } from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Typography } from "@mui/material";
// component
import Iconify from "../../components/Iconify";
// store
import StudentStoreContext from "./studentContext";
import { observer } from "mobx-react-lite";
import StudentStore from "../../stores/studentStore";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3, 3),
  boxShadow: theme.customShadows.z12,
}));

const ID = styled("div")(({ theme }) => ({
  fontSize: "0.8rem",
  fontWeight: "bold",
  color: theme.palette.primary.darker,
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.primary.lighter,
  borderRadius: theme.shape.borderRadius,
}));

// ----------------------------------------------------------------------

const Content = observer(({ store }: { store: StudentStore }) => {
  return (
    <RootStyle>
      <Stack direction="column" spacing={1}>
        <ID>ID: {store.id}</ID>
        <Stack direction="row" spacing={2} alignItems="center">
          <Iconify icon="eva:person-fill" />
          <Typography variant="body2">{store.username}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Iconify icon="eva:email-fill" />
          <Typography variant="body2">{store.email}</Typography>
        </Stack>
      </Stack>
    </RootStyle>
  );
});

const StudentInfo = observer(() => {
  const store = useContext(StudentStoreContext);
  return <Content store={store} />;
});

export default StudentInfo;
