import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import Iconify from "../../components/Iconify";
import MenuPopover from "../../components/MenuPopover";
//
import { useLoginStateStore } from "../../stores/useStore";
import LoginStateStore from "../../stores/loginStateStore";
import avatarDetault from "../../resources/mock-images/avatars/avatar_default.jpg";

// ----------------------------------------------------------------------

type MenuOptionsType = {
  label: string;
  icon: string;
  linkTo: string;
};

const MENU_OPTIONS: MenuOptionsType[] = [
  {
    label: "编辑信息",
    icon: "eva:edit-fill",
    linkTo: "/dashboard/editInfo",
  },
  {
    label: "修改密码",
    icon: "eva:edit-fill",
    linkTo: "/dashboard/changePassword",
  },
];

// ----------------------------------------------------------------------

const Content = observer(
  ({ loginStateStore }: { loginStateStore: LoginStateStore }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              "&:before": {
                zIndex: 1,
                content: "''",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                position: "absolute",
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
          }}
        >
          <Avatar src={avatarDetault} alt="photoURL" />
        </IconButton>

        <MenuPopover
          open={open}
          onClose={handleClose}
          anchorEl={anchorRef.current}
          sx={{ width: 220 }}
        >
          <Box sx={{ my: 1.5, px: 2.5 }}>
            <Typography variant="subtitle1" noWrap>
              {loginStateStore.currentUserStore.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
              {loginStateStore.currentUserStore.email}
            </Typography>
          </Box>

          <Divider sx={{ my: 1 }} />

          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
              sx={{ typography: "body2", py: 1, px: 2.5 }}
            >
              <Iconify
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24,
                }}
              />

              {option.label}
            </MenuItem>
          ))}

          <Box sx={{ p: 2, pt: 1.5 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              onClick={() => {
                loginStateStore.logout();
                window.location.href = "/login";
              }}
            >
              登出
            </Button>
          </Box>
        </MenuPopover>
      </>
    );
  }
);

export default function AccountPopover() {
  const loginStateStore = useLoginStateStore();
  return <Content loginStateStore={loginStateStore} />;
}
