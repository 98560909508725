import { observer } from "mobx-react-lite";
// material
import { styled } from "@mui/material/styles";
import { Typography, Stack, Grid } from "@mui/material";
import GameStatusPanel from "./gameStatusPanel";
import { useCurrentStudentStore } from "../../stores/useStore";
import GameGeometryStore from "../../stores/gameGeometryStore";

// ----------------------------------------------------------------------

const ProgressTextStyle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

const LevelProgressStyle = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 1),
  minWidth: 60,
  backgroundColor: "rgba(255, 255, 255, 0.3)",
  borderRadius: theme.shape.borderRadius,
  textAlign: "center",
}));

const LevelProgress = ({
  title,
  progress,
}: {
  title: string;
  progress: string;
}) => {
  return (
    <Grid item xs="auto">
      <LevelProgressStyle alignItems="center">
        <Typography variant="subtitle1">{title}</Typography>
        {progress}
      </LevelProgressStyle>
    </Grid>
  );
};

const Content = observer(
  ({
    hideEntryButton,
    gameGeometry,
  }: {
    hideEntryButton: boolean;
    gameGeometry: GameGeometryStore;
  }) => {
    return (
      <GameStatusPanel
        gameId="geometry"
        title="几何迷踪"
        themeColor="#4755D6"
        lastPlayTime={gameGeometry.lastPlayTime}
        hideEntryButton={hideEntryButton}
        cornerWidget={
          <ProgressTextStyle variant="subtitle1">
            进度：{gameGeometry.progressText}
          </ProgressTextStyle>
        }
      >
        <Grid container rowSpacing={1.2} columnSpacing={2}>
          <LevelProgress
            title="泰勒斯定理"
            progress={gameGeometry.chapterProgressText.thalesTheorem}
          />
          <LevelProgress
            title="算额"
            progress={gameGeometry.chapterProgressText.sangaku}
          />
          <LevelProgress
            title="欧拉"
            progress={gameGeometry.chapterProgressText.euler}
          />
        </Grid>
      </GameStatusPanel>
    );
  }
);

const GameStatusGeometry = ({
  hideEntryButton = false,
}: {
  hideEntryButton?: boolean;
}) => {
  const { gameGeometry } = useCurrentStudentStore();
  return <Content hideEntryButton={hideEntryButton} gameGeometry={gameGeometry} />;
};

export default GameStatusGeometry;
