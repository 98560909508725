import { makeObservable, observable, action, runInAction } from "mobx";
import gameNimStateService, {
  GameNimLevelState,
} from "../services/gameNimStateService";

export default class GameNimStore {
  studentId: string;
  lastPlayTime?: Date;
  singleLineState: GameNimLevelState[] = [];
  multipleLineState: GameNimLevelState[] = [];

  constructor(studentId: string) {
    this.studentId = studentId;
    makeObservable(this, {
      lastPlayTime: observable,
      singleLineState: observable,
      multipleLineState: observable,
      update: action,
    });
  }

  async update() {
    const { lastPlayTime, singleLineState, multipleLineState } =
      await gameNimStateService({ studentId: this.studentId });
    runInAction(() => {
      this.lastPlayTime = lastPlayTime;
      this.singleLineState = singleLineState;
      this.multipleLineState = multipleLineState;
    });
  }
}
