import { ReactElement } from "react";
// component
import Iconify from "../../components/Iconify";
import { UserRole } from "../../stores/userStore";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} />
);

type SidebarConfig = {
  title: string;
  path: string;
  icon: ReactElement;
};

const sidebarConfig: (role: UserRole) => SidebarConfig[] = (role) => {
  switch (role) {
    case UserRole.ADMIN:
      return [
        {
          title: "仪表盘",
          path: "/dashboard/app",
          icon: getIcon("eva:pie-chart-2-fill"),
        },
        {
          title: "我的学生",
          path: "/dashboard/students",
          icon: getIcon("eva:smiling-face-fill"),
        },
        {
          title: "用户管理",
          path: "/dashboard/user",
          icon: getIcon("eva:people-fill"),
        },
      ];
    case UserRole.USER:
      return [
        {
          title: "仪表盘",
          path: "/dashboard/app",
          icon: getIcon("eva:pie-chart-2-fill"),
        },
        {
          title: "我的学生",
          path: "/dashboard/students",
          icon: getIcon("eva:smiling-face-fill"),
        },
      ];
  }
};

export default sidebarConfig;
