import { styled } from "@mui/material/styles";
import { Stack, TextField, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import { FormikProvider, Form, useFormik } from "formik";
import { useSnackbar } from "notistack";
import EditUserStore from "../../../stores/editUserStore";

const RootStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: "600px",
  width: "80vw",
}));

const AddStudentForm = observer(({ store }: { store: EditUserStore }) => {
  const { enqueueSnackbar } = useSnackbar();
  const schema = Yup.object().shape({
    usernameList: Yup.string().required("请填写需要添加的用户名"),
  });
  const formik = useFormik({
    initialValues: {
      usernameList: "",
    },
    validationSchema: schema,
    onSubmit: async (values, helper) => {
      try {
        const count = await store.addStudent({
          usernameList: values.usernameList,
        });
        helper.resetForm();
        enqueueSnackbar(`成功添加${count}个学生`, { variant: "success" });
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar(error.message, { variant: "error" });
          return;
        }
        enqueueSnackbar(`${error}`, { variant: "error" });
      }
    },
  });
  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  return (
    <RootStyle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack direction="column" spacing={1} alignItems="center">
            <TextField
              sx={{  }}
              fullWidth
              autoComplete="username"
              type="username"
              label="输入学生用户名，一行一个"
              multiline={true}
              rows={10}
              maxRows={10000}
              {...getFieldProps("usernameList")}
              error={Boolean(touched.usernameList && errors.usernameList)}
              helperText={touched.usernameList && errors.usernameList}
            />
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              添加学生
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
});

export default AddStudentForm;
