import { makeObservable } from "mobx";
import { createUser } from "../services/createUserService";

export default class CreateUserStore {
  constructor() {
    makeObservable(this, {});
  }

  async createUser(args: {username: string, name: string, email: string, role: string, password: string}) {
    await createUser(args);
  }
}