import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { Card, CardHeader, Box } from "@mui/material";
//
import { BaseOptionChart } from "../../../components/charts";
import { observer } from "mobx-react-lite";
import DashboardStore from "../../../stores/dashboardStore";

// ----------------------------------------------------------------------

const RecentLoginGraph = observer(({ store }: { store: DashboardStore }) => {
  const chartData = store.recentLoginGraphData.map((item) => item.count);
  const chartLabels = store.recentLoginGraphData.map((item) =>
    item.date.toLocaleDateString()
  );
  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: { type: ["solid", "gradient", "solid"] },
    labels: chartLabels,
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== "undefined") {
            return `${y.toFixed(0)} 次登录`;
          }
          return y;
        },
      },
    },
  });

  const series = [
    {
      name: "",
      type: "bar",
      data: chartData,
    },
  ];

  return (
    <Card>
      <CardHeader title="近期登录状况" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={series}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  );
});

export default RecentLoginGraph;
