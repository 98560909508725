import http from "../utils/axios";

export type GameGeometryState = {
  lastPlayTime?: Date;
  levels: GeometryLevelLevelState[];
};

export type GeometryLevelLevelState = {
  chapterIdentifier: string;
  levelIdentifier: string;
  isFinished: boolean;
  startCount: number;
  finishCount: number;
  lastPlayTime?: Date;
  firstTimeFinishDuration?: number;
  averageFinishDuration?: number;
  averageStepCount?: number;
  lowestStepCount?: number;
  shortestSolution?: string[];
};

export default async function gameGeometryStateService(args: {
  studentId: string;
}): Promise<GameGeometryState> {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      lastPlayTime?: string;
      levels: {
        chapterIdentifier: string;
        levelIdentifier: string;
        isFinished: boolean;
        startCount: number;
        finishCount: number;
        lastPlayTime?: Date;
        firstTimeFinishDuration?: number;
        averageFinishDuration?: number;
        averageStepCount?: number;
        lowestStepCount?: number;
        shortestSolution?: string;
      }[];
    };
  }>(`/api/students/detail/${args.studentId}/game/geometry`);

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  const data = res.data.data;

  return {
    lastPlayTime: data?.lastPlayTime ? new Date(data.lastPlayTime) : undefined,
    levels:
      data?.levels.map((l) => ({
        chapterIdentifier: l.chapterIdentifier,
        levelIdentifier: l.levelIdentifier,
        isFinished: l.isFinished,
        startCount: l.startCount,
        finishCount: l.finishCount,
        lastPlayTime: l.lastPlayTime ? new Date(l.lastPlayTime) : undefined,
        firstTimeFinishDuration: l.firstTimeFinishDuration,
        averageFinishDuration: l.averageFinishDuration,
        averageStepCount: l.averageStepCount,
        lowestStepCount: l.lowestStepCount,
        shortestSolution: l.shortestSolution?.split(";"),
      })) ?? [],
  };
}
