import http from "../utils/axios";

export default async function changePasswordService(args: {
  oldPassword: string;
  newPassword: string;
}): Promise<void> {
  const res = await http.patch<{
    error: boolean;
    message?: string;
  }>("/api/users/changePassword", args);
  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return;
}