import http from "../utils/axios";

async function mock_refreshToken() {
  return { token: "123", refreshToken: "abc" };
}

export const refreshToken = mock_refreshToken;

// async function mock_login(args: { username: string; password: string }) {
//   return { token: "123", refreshToken: "abc" };
// }

async function live_login(args: { username: string; password: string }) {
  const res = await http.post<{
    error: boolean;
    message?: string;
    data?: {
      accessToken: string;
      refreshToken: string;
    };
  }>("/api/auth/login", args);
  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return {
    token: res.data.data?.accessToken ?? "",
    refreshToken: res.data.data?.refreshToken ?? "",
  };
}

export const login = live_login;
