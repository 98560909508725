import http from "../utils/axios";

export default async function bulkAddStudentService(args: {
  usernameList: string;
  userId: string;
}): Promise<number> {
  const res = await http.post<{
    error: boolean;
    message?: string;
    data?: {
      addedCount: number;
    }
  }>("/api/students/bulkAdd", args);
  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return res.data.data?.addedCount ?? 0;
}
