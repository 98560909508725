import http from "../utils/axios";

export type DashboardInfo = {
  totalRegisteredUserCount?: number;
  studentCount?: number;
  gameCount?: number;

  gamePopularity: { title: string; count: number; color: string }[];
  mostActiveStudents: {
    id: string;
    username: string;
    lastLoginTime: Date;
  }[];
  recentLoginGraphData: { date: Date; count: number }[];
};

export default async function dashboardService(): Promise<DashboardInfo> {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      registrantCount: number;
      studentCount: number;
      gameCount: number;
      recentLoginState: { date: string; count: number }[];
      gamePlayCount: { gameIdentifier: string; count: number }[];
      loginRanking: {
        id: string;
        username: string;
        lastLoginTime: string;
      }[];
    };
  }>("/api/dashboard/statistics");

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  const data = res.data.data;

  return {
    totalRegisteredUserCount: data?.registrantCount ?? 0,
    studentCount: data?.studentCount ?? 0,
    gameCount: data?.gameCount ?? 0,
    gamePopularity: (data?.gamePlayCount ?? []).map((item) => {
      let title: string = "";
      let color: string = "";
      switch (item.gameIdentifier) {
        case "hanoi":
          title = "汉诺塔";
          color = "#E44B6D";
          break;
        case "nim":
          title = "尼姆游戏";
          color = "#FD6E22";
          break;
      }
      return { title: title, count: item.count, color: color };
    }),
    mostActiveStudents: (data?.loginRanking ?? []).map((item) => {
      return {
        id: item.id,
        username: item.username,
        lastLoginTime: new Date(item.lastLoginTime),
      }
    }),
    recentLoginGraphData: (data?.recentLoginState ?? []).map((item) => {
      return { date: new Date(item.date), count: item.count };
    }),
  };
}
