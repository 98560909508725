import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer, Stack } from "@mui/material";
// mocks_
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import sidebarConfig from "./SidebarConfig";
import { useLoginStateStore } from "../../stores/useStore";
import LoginStateStore from "../../stores/loginStateStore";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const ShadowedLogo = styled(Logo)(({ theme }) => ({
  boxShadow: theme.customShadows.z24,
}));

// ----------------------------------------------------------------------

const Content = observer(
  ({
    isOpenSidebar,
    onCloseSidebar,
    loginStateStore,
  }: {
    isOpenSidebar: boolean;
    onCloseSidebar: () => void;
    loginStateStore: LoginStateStore;
  }) => {
    const isDesktop = useResponsive("up", "lg");

    const renderContent = (
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Stack
          direction="row"
          spacing="12px"
          alignItems="center"
          sx={{ px: 2.5, py: 3 }}
        >
          <ShadowedLogo />
          <Box component="h1" sx={{ fontSize: "1.4rem" }}>
            数学嘉年华
          </Box>
        </Stack>

        <NavSection
          navConfig={sidebarConfig(loginStateStore.currentUserStore.role)}
        />

        <Box sx={{ flexGrow: 1 }} />
      </Scrollbar>
    );

    return (
      <RootStyle>
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: "background.default",
                borderRightStyle: "dashed",
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </RootStyle>
    );
  }
);

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}) {
  const loginStateStore = useLoginStateStore();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Content
      isOpenSidebar={isOpenSidebar}
      onCloseSidebar={onCloseSidebar}
      loginStateStore={loginStateStore}
    />
  );
}
