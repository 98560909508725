import { makeObservable, observable, computed } from "mobx";

import StudentListStore from "./studentListStore";
import UserListStore from "./userListStore";
import CurrentUserStore from "./currentUserStore";
import DashboardStore from "./dashboardStore";
import LoginStateStore from "./loginStateStore";

export default class AppStore {
  studentListStore?: StudentListStore;
  userListStore?: UserListStore;
  currentUserStore = new CurrentUserStore();
  loginStateStore = new LoginStateStore(this.currentUserStore);
  dashboardStore = new DashboardStore();

  get isLoggedIn() {
    return this.loginStateStore.isLoggedIn;
  }

  constructor() {
    makeObservable(this, {
      studentListStore: observable,
      userListStore: observable,
      currentUserStore: observable,
      loginStateStore: observable,
      isLoggedIn: computed,
    });
  }

  logout() {
    this.currentUserStore.clear();
    this.studentListStore = undefined;
    this.loginStateStore.logout();
    this.currentUserStore = new CurrentUserStore();
    this.loginStateStore = new LoginStateStore(this.currentUserStore);
    this.dashboardStore = new DashboardStore();
    this.userListStore = undefined;
    this.studentListStore = undefined;
  }
}

