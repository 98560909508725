import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import Register from "./pages/Register";
import DashboardApp from "./pages/DashboardApp";
import User from "./pages/UserList";
import NotFound from "./pages/Page404";
import StudentList from "./pages/StudentList";
import StudentDetail from "./pages/StudentDetail";
import GameDetail from "./pages/GameDetail";
import CreateUser from "./pages/CreateUser";
import { useLoginStateStore } from "./stores/useStore";
import { UserRole } from "./stores/userStore";
import ChangePassword from "./pages/ChangePassword";

// ----------------------------------------------------------------------

function AdminRequried({ child }: { child: JSX.Element }): JSX.Element {
  const loginStateStore = useLoginStateStore();
  if (loginStateStore.currentUserStore.role === UserRole.ADMIN) {
    return child;
  }
  return <Navigate to="/404" />;
}

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp /> },
        { path: "students", element: <StudentList /> },
        { path: "student/:id", element: <StudentDetail /> },
        { path: "student/:studentId/game/:gameId", element: <GameDetail /> },
        {
          path: "user",
          element: <AdminRequried child={<User />} />,
        },
        {
          path: "createUser",
          element: <AdminRequried child={<CreateUser />} />,
        },
        { path: "changePassword", element: <ChangePassword /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard/app" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
