import {
  makeObservable,
  observable,
  computed,
  runInAction,
} from "mobx";
import gameHanoiStateService from "../services/gameHanoiStateService";

export default class GameHanoiStore {
  studentId: string;
  lastPlayTime?: Date;
  levelState: {
    title: string;
    isFinished: boolean;
    lastPlayTime?: Date;
    averageDuration?: number;
    submitCount: number;
    averageStep?: number;
  }[] = [];

  get progress(): string {
    const finishedCount = this.levelState.filter(
      (item) => item.isFinished
    ).length;
    return `${finishedCount} / ${this.levelState.length}`;
  }

  constructor(studentId: string) {
    this.studentId = studentId;
    makeObservable(this, {
      levelState: observable,
      lastPlayTime: observable,
      progress: computed,
    });
  }

  async update() {
    const { lastPlayTime, levels } = await gameHanoiStateService({
      studentId: this.studentId,
    });
    runInAction(() => {
      this.lastPlayTime = lastPlayTime;
      this.levelState = levels.map((item) => ({
        ...item,
        title: (() => {
          switch (item.levelId) {
            case "level1":
              return "1层";
            case "level2":
              return "2层";
            case "level3":
              return "3层";
            case "level4":
              return "4层";
            case "level5":
              return "5层";
            case "levelN":
              return "N层";
            case "levelExplanation":
              return "汉诺塔解析";
            default:
              return item.levelId;
          }
        })(),
        averageDuration:  item.averageFinishDuration,
        averageStep: item.submitCount === 0 ? undefined : item.averageStep,
        }));
    });
  }
}
