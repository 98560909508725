// icons
import { Icon } from '@iconify/react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export type IconifyProps = {
  icon: string | React.ReactElement;
} & BoxProps

export default function Iconify({ icon,...other }: IconifyProps) {
  return <Box component={Icon} icon={icon} {...other} />;
}
