import { useContext } from "react";
import StudentStoreContext from "../sections/@studentDetail/studentContext";
import StudentListStore from "./studentListStore";
import UserListStore from "./userListStore";
import StudentStore from "./studentStore";
import AppStore from "./appStore";

const appStore = new AppStore();

export function useAppStore() {
  return appStore;
}

export function useLoginStateStore() {
  return appStore.loginStateStore;
}

export function useStudentListStore() {
  const store = appStore;
  if (!store) {
    throw new Error("useStudentListStore must be used within a StoreProvider");
  }
  if (!store.studentListStore) {
    const listStore = new StudentListStore();
    store.studentListStore = listStore;
    return listStore;
  }
  return store.studentListStore;
}

export function useUserListStore() {
  const store = appStore;
  if (!store) {
    throw new Error("useUserListStore must be used within a StoreProvider");
  }
  if (!store.userListStore) {
    const listStore = new UserListStore();
    store.userListStore = listStore;
    return listStore;
  }
  return store.userListStore;
}

export function useStudentStore(id: string) {
  const store = appStore;
  if (!store) {
    throw new Error("useStudentStore must be used within a StoreProvider");
  }
  if (!store.studentListStore) {
    return new StudentStore({ id });
  }
  const existedStore = store.studentListStore.currentPage.students.find((obj) => obj.id === id);
  if (!existedStore) {
    return new StudentStore({ id });
  }
  return existedStore;
}

export function useCurrentStudentStore() {
  const store = useContext(StudentStoreContext);
  return store;
}

export function useDashboardStore() {
  const store = appStore;
  return store.dashboardStore;
}