import { Link as RouterLink } from "react-router-dom";
// material
import {
  Box,
  Stack,
  Link,
  Card,
  Button,
  Divider,
  Typography,
  CardHeader,
} from "@mui/material";
//
import Scrollbar from "../../../components/Scrollbar";
import Iconify from "../../../components/Iconify";
import { observer } from "mobx-react-lite";
import DashboardStore from "../../../stores/dashboardStore";

// ----------------------------------------------------------------------

function StudentItem({
  id,
  username,
  recentLoginTime,
}: {
  id: string;
  username: string;
  recentLoginTime: Date;
}) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Box sx={{ minWidth: 120 }}>
        <Link
          to={`/dashboard/student/${id}`}
          color="inherit"
          underline="hover"
          component={RouterLink}
        >
          <Typography variant="subtitle2" noWrap>
            {username}
          </Typography>
        </Link>
      </Box>
      <Typography
        variant="caption"
        sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
      >
        最近登录于 {recentLoginTime.toLocaleDateString()}
      </Typography>
    </Stack>
  );
}

const MostActiveStudents = observer(({ store }: { store: DashboardStore }) => {
  return (
    <Card>
      <CardHeader title="最近登录学生" />

      <Scrollbar>
        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {store.mostActiveStudents.map((student) => (
            <StudentItem
              key={student.id}
              id={student.id}
              username={student.username}
              recentLoginTime={student.lastLoginTime}
            />
          ))}
        </Stack>
      </Scrollbar>

      <Divider />

      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          to="/dashboard/students"
          size="small"
          color="inherit"
          component={RouterLink}
          endIcon={<Iconify icon="eva:arrow-ios-forward-fill" />}
        >
          查看所有学生
        </Button>
      </Box>
    </Card>
  );
});

export default MostActiveStudents;
