// import students from "../_mocks_/students";
import http from "../utils/axios";

export type StudentDetailContent = {
  id: string;
  username: string;
  email: string;
  lastLoginTime?: Date;
  isVerified: boolean;
};

// async function mock_studentDetailService(args: {
//   studentId: string;
// }): Promise<StudentDetailContent> {
//   const found = students.find((student) => student.id === args.studentId);
//   if (!found) {
//     throw Error("没有找到学生");
//   }
//   return {
//     id: found.id,
//     username: found.username,
//     email: found.email,
//     lastLoginTime: found.lastLoginTime,
//     isVerified: found.isVerified,
//   };
// }

async function live_studentDetailService(args: {
  studentId: string;
}): Promise<StudentDetailContent> {
  const res = await http.get<{
    error: boolean;
    message?: string;
    data?: {
      id: string;
      username: string;
      email: string;
      activated: boolean;
      lastLoginTime?: string;
    };
  }>(`/api/students/detail/${args.studentId}`);

  if (res.data.error) {
    throw new Error(res.data.message);
  }

  return {
    id: res.data.data?.id ?? "", 
    username: res.data.data?.username ?? "",
    email: res.data.data?.email ?? "",
    lastLoginTime: (() => {
      if (res.data.data?.lastLoginTime) {
        return new Date(res.data.data.lastLoginTime);
      }
      return undefined;
    })(),
    isVerified: res.data.data?.activated ?? false,
  };
}

export default live_studentDetailService;
