import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number) {
  return numeral(number / 100).format("0.0%");
}

export function fNumber(number: number) {
  return numeral(number).format();
}

export function fShortenNumber(number: number) {
  return replace(numeral(number).format("0.00a"), ".00", "");
}

export function fData(number: number) {
  return numeral(number).format("0.0 b");
}

export function fDuration(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const secondsLeft = seconds % 60;
  if (minutes < 1) {
    return `${Math.floor(secondsLeft)}秒`;
  }
  return `${minutes}分${Math.floor(secondsLeft)}秒`;
}