import { ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { forwardRef } from 'react';
// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

type PageProps = {
  children: ReactNode;
  title?: string;
} & BoxProps;

/** All pages should have this component as a root.
 *  It's basically a wrapper over a Box with a title.
 */ 
const Page = forwardRef(({ children, title = "", ...other }: PageProps, ref) => (
  <Box ref={ref} {...other}>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    {children}
  </Box>
));

export default Page;
